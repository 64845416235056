import moment from "moment";
import { CiCalendarDate } from "react-icons/ci";
import { FaRegUser } from "react-icons/fa";
import { IoInformationCircleOutline } from "react-icons/io5";

import ReservationDetailBg from "../../assets/img/reservation-details.png";
import ReservationDetailBgDark from "../../assets/img/reservation-details-dark.png";

import CarFrame from "../../assets/img/car-frame.png";
import CarFrameDark from "../../assets/img/car-frame-dark.png";

const HoveredReservation = ({ hoveredReservation, theme }) => {
  console.log(hoveredReservation);
  return (
    <div
      className="reservation-tooltip"
      style={{
        position: "fixed",
        top: `${hoveredReservation.position.y + 10}px`, // Offset slightly below the cursor
        left: `${hoveredReservation.position.x}px`,
        zIndex: 1000,
        transform: "translate(-50%, 10%)", // Center the tooltip and adjust height
        // background: `url(${
        //   theme === "light" ? ReservationDetailBg : ReservationDetailBgDark
        // })`,
        background: theme === "light" ? "#f5f7f9" : "#4b506f",
        borderRadius: "15px",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
      }}
    >
      <div className="tooltip-container">
        {/* Header Section */}
        <div className="tooltip-header">
          <span className="tooltip-info-icon">
            <IoInformationCircleOutline />
          </span>
          <span className="tooltip-header-text">Info de réservation</span>
        </div>

        {/* Address Information */}
        <div className="tooltip-address-section">
          <>
            {hoveredReservation?.reservation?.client_type === "Entreprise" && (
              <div className="tooltip-address-item">
                <strong>
                  <FaRegUser />{" "}
                  {hoveredReservation?.reservation?.customer_id?.business}
                </strong>
                <p>
                  {
                    hoveredReservation.reservation.customer_id.company
                      .company_address
                  }
                </p>
              </div>
            )}
          </>

          <div className="tooltip-address-item">
            <strong>
              <FaRegUser />{" "}
              {hoveredReservation.reservation?.customer_id?.firstname}{" "}
              {hoveredReservation.reservation?.customer_id?.lastname}
            </strong>
            <p>{hoveredReservation.reservation?.customer_id?.address}</p>
          </div>
        </div>

        <div className="details-resv">
          {/* Departure and Return Details */}
          <div className="tooltip-dates-section">
            {/* Departure Section */}
            <div className="tooltip-depart">
              <div className="tooltip-date-header">
                <span className="tooltip-date-label">Départ</span>
              </div>
              <div className="tooltip-date-time">
                {hoveredReservation.reservation.dept_loc}
              </div>
              <div className="tooltip-date-time">
                {hoveredReservation?.reservation?.dept_time}
              </div>
            </div>

            {/* Car Icon */}
            <div className="tooltip-icon-separator">
              <img
                src={theme === "light" ? CarFrame : CarFrameDark}
                height={20}
                width={90}
              />
            </div>

            {/* Return Section */}
            <div className="tooltip-retour">
              <div className="tooltip-date-header">
                <span className="tooltip-date-label">Retour</span>
              </div>
              <div
                className="tooltip-date-time"
                // style={{
                //   fontSize: "12px",
                //   lineHeight: "12px",
                //   fontWeight: 400,
                //   display: "-webkit-box",
                //   WebkitLineClamp: 2, // Limit text to 2 lines
                //   WebkitBoxOrient: "vertical",
                //   overflow: "hidden",
                //   textOverflow: "ellipsis",
                // }}
              >
                {hoveredReservation.reservation.return_loc}
              </div>

              <div className="tooltip-date-time">
                {hoveredReservation?.reservation?.return_time}
              </div>
            </div>
          </div>
          <div className="d-flex flex-nowrap align-items-center">
            <div className="ticket-bubble left" />
            <hr
              style={{
                borderColor: "#000",
                borderStyle: "dashed",
                width: "100%",
              }}
            />

            <div className="ticket-bubble right" />
          </div>
          <div className="reserved-dates">
            <div className="reserved-date">
              <CiCalendarDate />{" "}
              {moment(hoveredReservation?.reservation?.start_date).format(
                "DD/MM/YYYY"
              )}
            </div>
            <div className="reserved-date">
              <CiCalendarDate />{" "}
              {moment(hoveredReservation?.reservation?.return_date).format(
                "DD/MM/YYYY"
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HoveredReservation;
