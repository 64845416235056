import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  Collapse,
  FormControlLabel,
  IconButton,
  InputBase,
  Paper,
  Typography,
} from "@mui/material";
import { ReactComponent as DropArrow } from "../../../../../assets/icons/down-drop.svg";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import EditIcon from "../../../../../assets/img/edit.svg";
import { useOutletContext, useParams } from "react-router-dom";
import { fetchSingleVisibilite } from "../../../../../Redux/vehicleSlice";
import { ReactComponent as DownArr } from "../../../../../assets/icons/down-arr.svg";
import { toast } from "react-toastify";
import {
  addOptionlist,
  addVisibilite,
} from "../../../../../services/AddVehicle";

const Caractristics = () => {
  const { selectedLanguage } = useOutletContext();
  const [hideSection, setHideSection] = useState(true);
  const { singleVisibiliteData } = useSelector((state) => state.vehicleSlice);
  const [isEdit, setEdit] = useState(true);
  const [checked, setLChecked] = useState(
    selectedLanguage.toUpperCase() === "EN"
  );

  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    dispatch(fetchSingleVisibilite(id));
  }, [id]);

  useEffect(() => {
    setLChecked(selectedLanguage.toUpperCase() || "EN");
  }, [selectedLanguage]);

  const formik = useFormik({
    initialValues: {
      One_Of_One: false,
      Electrique: false,
      Engine: "",
      Consumption: "",
      V_Max: "",
      RangeData: "",
      Option_Interior: "",
      Option_Exterieur: "",
      Option_Confort: "",
      description: "",
    },
    onSubmit: async (values) => {
      try {
        const tempData = {
          visibiliteData: {
            general_info: id,
            ...values,
            Electrique: values.Electrique ? "Yes" : "No",
            One_Of_One: values.One_Of_One ? "Yes" : "No",
          },
        };
        await addVisibilite(tempData);

        const tempData2 = {
          ...singleVisibiliteData,
          optionListData: {
            general_info: id,
            ...values,
            lang: checked || "EN",
          },
        };
        await addOptionlist(tempData2);
        dispatch(fetchSingleVisibilite(id));
        setEdit(true);
      } catch (err) {
        toast.error(err?.response?.data?.message || "Something went wrong");
      }
    },
  });

  useEffect(() => {
    const lang = checked || "EN";
    if (singleVisibiliteData?.visibiliteData) {
      formik.setFieldValue(
        "One_Of_One",
        singleVisibiliteData?.visibiliteData?.One_Of_One === "Yes"
      );
      formik.setFieldValue(
        "Electrique",
        singleVisibiliteData?.visibiliteData?.Electrique === "Yes"
      );
      formik.setFieldValue(
        "Engine",
        singleVisibiliteData?.visibiliteData?.Engine || ""
      );
      formik.setFieldValue(
        "Consumption",
        singleVisibiliteData?.visibiliteData?.Consumption || ""
      );
      formik.setFieldValue(
        "V_Max",
        singleVisibiliteData?.visibiliteData?.V_Max || ""
      );
      formik.setFieldValue(
        "RangeData",
        singleVisibiliteData?.visibiliteData?.RangeData || ""
      );
    }
    if (singleVisibiliteData?.optionListData) {
      formik.setFieldValue(
        "Option_Interior",
        singleVisibiliteData?.optionListData?.Option_Interior[lang] || ""
      );
      formik.setFieldValue(
        "description",
        singleVisibiliteData?.optionListData?.description[lang] || ""
      );
      formik.setFieldValue(
        "Option_Confort",
        singleVisibiliteData?.optionListData?.Option_Confort[lang] || ""
      );
      formik.setFieldValue(
        "Option_Exterieur",
        singleVisibiliteData?.optionListData?.Option_Exterieur[lang] || ""
      );
    }
  }, [singleVisibiliteData, checked]);

  return (
    <Paper sx={{ boxShadow: "0px 4px 18px 0px #4B465C1A", p: 2 }}>
      <form onSubmit={formik.handleSubmit}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography
            color="#4B465C"
            fontSize={18}
            fontWeight={500}
            role="button"
            onClick={() => setHideSection(!hideSection)}
          >
            Caractéristiques &nbsp;
            <DropArrow height={18} />
          </Typography>
          <Box>
            {isEdit ? (
              <IconButton size="small" onClick={() => setEdit(false)}>
                <img src={EditIcon} alt="Edit" width={24} height={24} />
              </IconButton>
            ) : (
              <button
                type="submit"
                className="btn btn-primary waves-effect waves-light"
              >
                Save
              </button>
            )}
          </Box>
        </Box>
        <Collapse in={hideSection} timeout="auto" unmountOnExit>
          <Box sx={{ width: "100%", py: 3 }}>
            <div className="row">
              <div className="col-2">
                <h6 className="drive-section-head mb-2">Motorisation</h6>
                <InputBase
                  readOnly={isEdit}
                  name="Engine"
                  value={formik.values.Engine}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="Engine"
                  className={`drive-values-item ${
                    isEdit ? "hide-input no-border" : "form-control"
                  }`}
                />
              </div>
              <div className="col-2">
                <h6 className="drive-section-head mb-2">Consommation</h6>
                <InputBase
                  readOnly={isEdit}
                  name="Consumption"
                  value={formik.values.Consumption}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="Consumption"
                  className={`drive-values-item ${
                    isEdit ? "hide-input no-border" : "form-control"
                  }`}
                />
              </div>
              <div className="col-2">
                <h6 className="drive-section-head mb-2">Vitesse Max</h6>
                <InputBase
                  readOnly={isEdit}
                  name="V_Max"
                  value={formik.values.V_Max}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="V-Max"
                  className={`drive-values-item ${
                    isEdit ? "hide-input no-border" : "form-control"
                  }`}
                />
              </div>
              <div className="col-2">
                <h6 className="drive-section-head mb-2">0-100</h6>
                <InputBase
                  readOnly={isEdit}
                  name="RangeData"
                  value={formik.values.RangeData}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="0-100"
                  className={`drive-values-item ${
                    isEdit ? "hide-input no-border" : "form-control"
                  }`}
                />
              </div>
              <div className="col-2">
                <FormControlLabel
                  label="One Of One"
                  control={
                    <Checkbox
                      checked={formik.values.One_Of_One}
                      name="One_Of_One"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  }
                  disabled={isEdit}
                  labelPlacement="start"
                />
                <FormControlLabel
                  label="Electrique"
                  control={
                    <Checkbox
                      checked={formik.values.Electrique}
                      name="Electrique"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  }
                  disabled={isEdit}
                  labelPlacement="start"
                />
              </div>
            </div>
            <div className="mt-3">
              <Accordion elevation={0}>
                <AccordionSummary
                  expandIcon={<DownArr />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <h6>Description</h6>
                </AccordionSummary>
                <AccordionDetails>
                  <textarea
                    rows={3}
                    readOnly={isEdit}
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="description"
                    placeholder="Description"
                    className={`mb-4 ${
                      isEdit ? "hide-input text-lighter" : "form-control"
                    }`}
                  />
                </AccordionDetails>
              </Accordion>
              <Accordion elevation={0}>
                <AccordionSummary
                  expandIcon={<DownArr />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                >
                  <h6>Options intérieures</h6>
                </AccordionSummary>
                <AccordionDetails>
                  <textarea
                    rows={3}
                    readOnly={isEdit}
                    value={formik.values.Option_Interior}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="Option_Interior"
                    placeholder="Option Interior"
                    className={`mb-4 ${
                      isEdit ? "hide-input text-lighter" : "form-control"
                    }`}
                  />
                </AccordionDetails>
              </Accordion>
              <Accordion elevation={0}>
                <AccordionSummary
                  expandIcon={<DownArr />}
                  aria-controls="panel3-content"
                  id="panel3-header"
                >
                  <h6>Options extérieurs</h6>
                </AccordionSummary>
                <AccordionDetails>
                  <textarea
                    rows={3}
                    readOnly={isEdit}
                    value={formik.values.Option_Exterieur}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="Option_Exterieur"
                    placeholder="Option Exterieur"
                    className={`mb-4 ${
                      isEdit ? "hide-input text-lighter" : "form-control"
                    }`}
                  />
                </AccordionDetails>
              </Accordion>
              <Accordion elevation={0}>
                <AccordionSummary
                  expandIcon={<DownArr />}
                  aria-controls="panel4-content"
                  id="panel4-header"
                >
                  <h6>Options confort</h6>
                </AccordionSummary>
                <AccordionDetails>
                  <textarea
                    rows={3}
                    readOnly={isEdit}
                    value={formik.values.Option_Confort}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="Option_Confort"
                    placeholder="Option Confort"
                    className={`mb-4 ${
                      isEdit ? "hide-input text-lighter" : "form-control"
                    }`}
                  />
                </AccordionDetails>
              </Accordion>
            </div>
          </Box>
        </Collapse>
      </form>
    </Paper>
  );
};

export default Caractristics;
