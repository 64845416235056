import { createSlice } from "@reduxjs/toolkit";
import {
  carHistoryApi,
  clientById,
  filterReservationById,
  getUserDocument,
  searchCustomerApi,
} from "../services/client/addclientApi";

const clientsSlice = createSlice({
  name: "clientsSlice",
  initialState: {
    clientList: [],
    temClientData: [],
    loading: true,
    commonLoading: true,
    customerData: [],
    carHistoryList: [],
    futureReservationsList: [],
    historicalReservationsList: [],
    tempList: [],
    userDocumentList: [],
    invoiceList: [],
    allDocumentVerify: false,
  },
  reducers: {
    clientDataList: (state, action) => {
      const data = action.payload?.sort((a, b) =>
        a?.country?.localeCompare(b?.country)
      );
      state.clientList = data || { noData: "No Data Found" };
      state.temClientData = data || { noData: "No Data Found" };
    },

    alphabetical: (state, action) => {
      state.clientList = action.payload;
    },

    getCustomerData: (state, action) => {
      state.customerData = action.payload;
    },

    loader: (state, action) => {
      state.loading = action.payload;
    },
    commonLoder: (state, action) => {
      console.log("action", action.payload);
      state.commonLoading = action.payload;
    },

    getCarHistory: (state, action) => {
      state.carHistoryList = action.payload;
      state.tempList = action.payload;
    },

    getFilterReservation: (state, action) => {
      const sortedReservations = action.payload
        .slice()
        .sort((a, b) => new Date(a.start_date) - new Date(b.start_date));
      state.futureReservationsList = sortedReservations.filter(
        (item) => item.status !== "Archived"
      );
      state.historicalReservationsList = sortedReservations.filter(
        (item) => item.status === "Archived"
      );
    },

    searchCarHistory: (state, action) => {
      const data = state?.tempList?.filter((item) =>
        item?.general_id?.manufacturer?.name
          ?.toLowerCase()
          .includes(action.payload)
      );
      state.carHistoryList = data;
    },

    userDocument: (state, action) => {
      const filterDocument = action.payload?.documents?.filter(
        (doc) => doc?.status !== "reject"
      );
      const newData = { ...action.payload, documents: filterDocument };
      state.userDocumentList = newData;
    },

    documentVerification: (state, action) => {
      const { clientData, docData } = action.payload;
      if (clientData?.cust_type === "particulier") {
        const checkStatus = (documents) => {
          return documents?.every((doc) => doc.status === "accept");
        };

        if (checkStatus(docData?.documents)) {
          state.allDocumentVerify = true;
        } else {
          state.allDocumentVerify = false;
        }
      } else {
        const filterDocument = docData?.documents?.filter(
          (item) =>
            !item?.name?.includes("UBO (be)") &&
            !item?.name?.includes("RBE (lu/fr)")
        );

        const checkStatus = (documents) => {
          return documents?.every((doc) => doc.status === "accept");
        };

        if (checkStatus(filterDocument)) {
          state.allDocumentVerify = true;
        } else {
          state.allDocumentVerify = false;
        }
      }
    },
  },
});

export const {
  clientDataList,
  alphabetical,
  getCustomerData,
  loader,
  commonLoder,
  getCarHistory,
  getFilterReservation,
  searchCarHistory,
  userDocument,
  getInvoice,
  documentVerification,
} = clientsSlice.actions;
export default clientsSlice.reducer;

// api call
export const fetchClientList = (formData) => async (dispatch) => {
  try {
    const clientData = await searchCustomerApi(formData);
    console.log(clientData, "clientData");
    dispatch(clientDataList(clientData?.data));
  } catch (error) {
    console.error("Error fetching manufacturer dropdown:", error);
  }
};

export const fetchCustomerData = (id) => async (dispatch) => {
  try {
    const clientData = await clientById(id);
    dispatch(loader(true));
    dispatch(getCustomerData(clientData?.data));
    dispatch(loader(false));
  } catch (error) {
    console.error("Error fetching customer data:", error);
  }
};

export const fetchCarHistory = (id) => async (dispatch) => {
  try {
    const tempData = await carHistoryApi(id);
    dispatch(getCarHistory(tempData?.data));
  } catch (error) {}
};

export const fetchFilterReservationById = (id) => async (dispatch) => {
  try {
    dispatch(commonLoder(true));
    const tempData = await filterReservationById(id);
    dispatch(getFilterReservation(tempData?.data));
    dispatch(commonLoder(false));
  } catch (error) {}
};

export const fetchUserDocument = (id) => async (dispatch) => {
  try {
    const tempData = await getUserDocument(id);
    const clientData = await clientById(id);
    dispatch(
      documentVerification({
        clientData: clientData?.data?.customer,
        docData: tempData?.data,
      })
    );
    dispatch(userDocument(tempData?.data));
  } catch (error) {}
};

export const userDocumentVerification = (id) => async (dispatch) => {
  try {
    const tempData = await getUserDocument(id);
    const clientData = await clientById(id);
    dispatch(
      documentVerification({
        clientData: clientData?.data?.customer,
        docData: tempData?.data,
      })
    );
  } catch (error) {}
};
