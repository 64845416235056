import { FaFilter, FaSearch } from "react-icons/fa";

const ViewTypes = ({
  viewType,
  setViewType,
  searchTerm,
  toggleFilters,
  handleSearchChange,
  showFilters,
  focusCurrent,
}) => {
  return (
    <div
      className="head-side"
      style={{ width: showFilters ? "181px" : "281px" }}
    >
      <div className="view-buttons">
        <button
          className={`view-button ${viewType === "day" ? "selected" : ""}`}
          onClick={() => {
            setViewType("day");
            focusCurrent();
            // setTimeout(() => focusCurrent(), 1500);
          }}
        >
          {showFilters ? `J` : `Jours`}
        </button>
        <button
          className={`view-button ${viewType === "week" ? "selected" : ""}`}
          onClick={() => {
            setViewType("week");
            focusCurrent();
            // setTimeout(() => focusCurrent(), 1500);
          }}
        >
          {showFilters ? `S` : `Sem.`}
        </button>
        <button
          className={`view-button ${viewType === "month" ? "selected" : ""}`}
          onClick={() => {
            setViewType("month");
            focusCurrent();
            // setTimeout(() => focusCurrent(), 1500);
          }}
        >
          {showFilters ? `M` : `Mois`}
        </button>
      </div>

      <div className="search-container">
        <button className="filter-button" onClick={toggleFilters}>
          <FaFilter className="filter-icon" />
        </button>

        {/* Search Bar */}
        <div className="search-bar" style={{ width: "79%" }}>
          <FaSearch className="search-icon" />
          <input
            type="text"
            placeholder="Chercher"
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </div>
      </div>
    </div>
  );
};

export default ViewTypes;
