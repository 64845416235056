const CarsList = ({ vehicleDataLoc, handleCarImageClick, showFilters }) => {
  return (
    <div className="car-list">
      {vehicleDataLoc.map((vehicle, index) => (
        <div
          key={index}
          style={{
            height: "70px",
            borderBottom: "1px solid #F5F7F9",
            display: "flex",
            alignItems: "center",
            padding: "0px 10px",
          }}
          className="car-label-con"
          data-id={`${index}-car`}
        >
          <div className="car-label">
            {vehicle?.documents?.[0]?.document?.[0] && (
              <img
                src={`${process.env.REACT_APP_IMG_URL}${vehicle?.documents?.[0]?.document?.[0]}`}
                width={100}
                height={50}
                style={{ cursor: "pointer" }} // Indicates that the image is clickable
                onClick={() => handleCarImageClick(vehicle.general_id)} // Redirects to the car page
                alt={`${vehicle.manufacturer} ${vehicle.model}`}
              />
            )}
            <div>
              {!showFilters && (
                <div className="car-name">
                  <span className="bold-model">{vehicle.manufacturer}</span>{" "}
                  <span>{vehicle.model}</span>
                </div>
              )}
              {!showFilters && (
                <div className="car-plate d-flex flex-column">
                  {vehicle.license_plate} <div>{vehicle?.declination?.[0]}</div>
                </div>
              )}
              {showFilters && (
                <div className="car-plate-hh">{vehicle.license_plate}</div>
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default CarsList;
