/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import BreadCrumbs from "../../Common/BreadCrumbs";
import { useDispatch } from "react-redux";
import {
  fetchFuelDropdown,
  fetchManufacturerDropdown,
} from "../../../Redux/vehicleSlice";
import {
  fetchVehicleCategoryDropdown,
  fetchcompanyDropdown,
  fetchfinancetypeDropdown,
} from "../../../Redux/commonSlice";
import { useNavigate } from "react-router-dom";
import GeneralModal from "../Editvehicle/Modal/GeneralModal";
import { Paper } from "@mui/material";

const Addvehicle = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchManufacturerDropdown());
    dispatch(fetchFuelDropdown());
    dispatch(fetchVehicleCategoryDropdown());
    dispatch(fetchfinancetypeDropdown());
    dispatch(fetchcompanyDropdown());
  }, []);
  return (
    <div className="content-wrapper ">
      <div className="container-fluid flex-grow-1 container-p-y">
        <div className="d-flex align-items-center justify-content-between">
          <BreadCrumbs title={"Flotte"} subTitle={"Nouveau Véhicule"} />
        </div>
        <Paper sx={{ boxShadow: "0px 4px 18px 0px #4B465C1A", p: 2 }}>
          <GeneralModal show={true} onHide={() => navigate("/fleet")} />
        </Paper>
      </div>
    </div>
  );
};

export default Addvehicle;
