/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  Collapse,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import EditIcon from "../../../../../assets/img/edit.svg";
import { ReactComponent as DropArrow } from "../../../../../assets/icons/down-drop.svg";
import {
  getVehicleById,
  getVehicleWithTypes,
} from "../../../../../services/AddVehicle";
import {
  fetchMultiSetting,
  saveUpdateMultiSetting,
} from "../../../../../services/Drive";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { schengenCountries } from "./SingleParams";
import Badges from "./MultiParams/Badges";
import { BsCheck, BsX } from "react-icons/bs";
import Suggestions from "./MultiParams/Suggestions";
import { getCompanylist } from "../../../../../services/Company/addCompanyApi";
import { fetchPricing } from "../../../../../services/Pricing";

// Utility function to group vehicles by manufacturer and model
const groupVehicles = (vehicleData) => {
  const groupedMap = vehicleData.reduce((acc, vehicle) => {
    vehicle.title = `${vehicle.manufacturer} ${vehicle.model}`;
    const { manufacturer, model, color, ...restFields } = vehicle;
    const colorName = color?.name;
    const key = `${manufacturer}-${model}`;

    if (!acc[key]) {
      acc[key] = {
        manufacturer,
        model,
        colors: [],
        ...restFields,
      };
    }

    const colorIndex = acc[key].colors.findIndex((c) => c.color === colorName);

    if (colorIndex === -1) {
      acc[key].colors.push({
        color: colorName,
        vehicles: [vehicle],
      });
    } else {
      acc[key].colors[colorIndex].vehicles.push(vehicle);
    }

    return acc;
  }, {});

  return Object.values(groupedMap);
};

const MultiParams = () => {
  const { id } = useParams();
  const [isEdit, setEdit] = useState(true);
  const [hideSection, setHideSection] = useState(true);
  const [vehicles, setVehicles] = useState([]);
  const [companyList, setCompanylist] = useState([]);
  const [details, setDetails] = useState(null);

  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getVehicleWithTypes().then((res) => setVehicles(groupVehicles(res.data)));
    allCompanylist();
  }, []);

  useEffect(() => {
    setLoading(true);
    getVehicleById(id).then((res) => {
      setDetails(res?.data);
      setLoading(false);
    });
  }, [id]);

  useEffect(() => {
    if (id && vehicles.length > 0 && details) {
      setLoading(true);
      fetchMultiSetting(id).then((res) => {
        formik.setFieldValue("badges", res?.badges);

        const finalVals = vehicles
          .map((v) => {
            const check = res?.suggestions.find(
              (s) => s.model_id === v.vehicle_id
            );
            if (check) {
              v.color = check.color;
              return v;
            }
            return false;
          })
          .filter((r) => r);
        formik.setFieldValue("vehicleSelection", finalVals);
        formik.setFieldValue(
          "country",
          res?.countries?.map((f) => {
            return schengenCountries.find((r) => r.code === f);
          })
        );
        setLoading(false);
      });
    }
  }, [id, vehicles, details]);

  useEffect(() => {
    if (details && id) {
      const payload = {
        model_id: details?.General?.model?._id,
        manufacturer_id: details?.General?.manufacturer?._id,
        declination: details?.General?.declination,
      };
      setLoading(true);
      fetchPricing(payload)
        .then((res) => {
          setCompanylist(
            companyList.map((r) => {
              console.log(res?.companyPricing);
              console.log(r._id);
              const check = res?.companyPricing.find(
                (c) => c.company_id === r._id
              );
              console.log(check);
              if (check) return { ...r, price: check.price };
              return r;
            })
          );

          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [details]);

  const allCompanylist = async () => {
    const list = await getCompanylist();
    setCompanylist(list?.data || []);
  };

  // Initialize Formik for state management and submission
  const formik = useFormik({
    initialValues: {
      vehicleSelection: [], // Stores selected vehicles with color choices
      badges: [], // Stores selected badges
      country: [], // Stores selected countries
    },
    onSubmit: async (values) => {
      setSubmitting(true);
      // Extract the final manufacturer_id, selected colors, and badges from the state
      const finalVehicleSelection = values.vehicleSelection.map((selected) => ({
        manufacturer_id: selected.manufacturer_id,
        model_id: selected.vehicle_id,
        color: selected.color || "Default", // Assign "Default" if no color is selected
      }));

      const finalBadges = values.badges;
      try {
        const payload = {
          badges: finalBadges,
          suggestions: finalVehicleSelection,
          countries: values.country?.map((r) => r?.code),
          companyPricing: companyList.map((r) => ({
            company_id: r._id,
            price: r.price,
          })),
        };
        await saveUpdateMultiSetting(id, payload);
        toast.success("Updated successfully.");
        setEdit(true);
        setSubmitting(false);
      } catch (err) {
        setSubmitting(false);
        toast.error(err?.response?.data?.message || "Something went wrong");
      }
    },
  });

  return (
    <Paper
      sx={{ boxShadow: "0px 4px 18px 0px #4B465C1A", p: 2, borderRadius: 0 }}
    >
      <form onSubmit={formik.handleSubmit}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography
            color="#4B465C"
            fontSize={18}
            fontWeight={500}
            role="button"
            onClick={() => setHideSection(!hideSection)}
          >
            Paramètres Multi &nbsp;
            <DropArrow height={18} />
            {loading && <CircularProgress size={20} sx={{ ml: 2 }} />}
          </Typography>
          {isEdit ? (
            <IconButton size="small" onClick={() => setEdit(false)}>
              <img src={EditIcon} alt="Edit" width={24} height={24} />
            </IconButton>
          ) : (
            <></>
          )}
        </Box>
        <Collapse
          collapsedSize={0}
          in={hideSection}
          timeout="auto"
          unmountOnExit
        >
          <div className="row gap-5 mt-2">
            {companyList.map((comp) => (
              <div className="col-1">
                <div className="drive-section-head mb-2">{comp?.name}</div>
                <div className="input-w-endorment">
                  <input
                    className={`drive-values-item plain-input ${
                      !isEdit ? "" : "no-border"
                    }`}
                    placeholder="0000"
                    disabled={isEdit}
                    onChange={(e) =>
                      setCompanylist((prev) => [
                        ...prev.map((r) =>
                          r._id === comp._id
                            ? { ...r, price: e.target.value }
                            : r
                        ),
                      ])
                    }
                    value={comp.price}
                  />
                </div>
              </div>
            ))}
            <div className="col-2">
              <Badges formik={formik} isEdit={isEdit} />
            </div>
            <div className="col-3">
              <Suggestions
                formik={formik}
                isEdit={isEdit}
                vehicles={vehicles}
              />
            </div>
          </div>

          {!isEdit && (
            <div className="d-flex gap-2 justify-content-end mt-3">
              <button
                type="submit"
                className="btn btn-primary waves-effect waves-light"
                disabled={submitting}
              >
                <BsCheck style={{ fontSize: "20px" }} />
                &nbsp;&nbsp; VALIDER
              </button>
              <button
                onClick={() => setEdit(true)}
                className="btn border"
                disabled={submitting}
              >
                <BsX style={{ fontSize: "20px" }} />
                &nbsp;&nbsp; ANNULER
              </button>
            </div>
          )}
        </Collapse>
      </form>
    </Paper>
  );
};

export default MultiParams;
